<template>
  <v-card tile>
    <v-toolbar flat color="app-blue" dark class="card-header">
      <span class="text-h5 font-weight-light" v-if="task.id">
        {{ $t('tasks.editTask') }}
      </span>
      <span class="text-h5 font-weight-light" v-else>
        {{ $t('tasks.createTask') }}
      </span>
      <v-spacer></v-spacer>
      <v-btn text icon @click="$emit('close')" class="mr-0">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-container grid-list-md class="pa-6 pb-0">
      <v-form ref="manageGroupMissionTaskForm">
        <v-text-field
          solo
          v-model="task.title"
          :rules="[rules.required]"
          :label="$t('common.title')"
          :disabled="task.completed"
        ></v-text-field>
        <v-textarea
          solo
          v-model="task.notes"
          :label="$t('common.notes')"
          :disabled="task.completed"
        ></v-textarea>
        <v-text-field
          solo
          :min="rubyEnum.min"
          :max="rubyEnum.max"
          :rules="[rules.required, rules.isInteger, rules.minMaxRuby]"
          v-model="task.ruby"
          :label="$t('common.ruby')"
          :disabled="task.completed"
          :hint="
            $t('common.minMaxValue', {
              min: rubyEnum.min,
              max: rubyEnum.max
            })
          "
        ></v-text-field>
        <v-select
          v-model="task.tags"
          :items="mappedTags"
          :rules="[rules.maxArrayLength]"
          chips
          :label="$t('tags.tags')"
          item-value="id"
          item-text="name"
          multiple
          solo
          class="tags-select"
          :disabled="task.completed"
        >
          <template #selection="{ item }">
            <v-chip color="app-blue">{{ item.name }}</v-chip>
          </template>
        </v-select>
        <h3>{{ $t('tasks.type') }}</h3>
        <v-radio-group v-model="task.type" row :disabled="task.completed">
          <v-radio
            :label="$t('tasks.todo')"
            :value="taskTypes.todo"
            color="app-blue"
          ></v-radio>
          <v-radio
            :label="$t('tasks.daily')"
            :value="taskTypes.daily"
            color="app-blue"
          ></v-radio>
          <v-radio
            :label="$t('tasks.weekly')"
            :value="taskTypes.weekly"
            color="app-blue"
          ></v-radio>
        </v-radio-group>
      </v-form>
    </v-container>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="app-blue" text @click="save()" :disabled="task.completed">
        {{ $t('common.save') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  ADD_GROUP_MISSION_TASK,
  UPDATE_GROUP_MISSION_TASK
} from '../../../../store/missions/tasks/types';
import validators from '@/common/validators';
import rubyEnum from '../../../../enums/ruby';
import taskTypes from '../../../../enums/task.types';

const MIN_TAGS = 0;
const MAX_TAGS = 3;

export default {
  name: 'manage-group-mission-task-modal',
  props: {
    selectedTask: Object,
    missionId: {
      type: String,
      require: true
    },
    selectedTypeFilter: {
      type: String,
      default: taskTypes.todo
    }
  },
  computed: {
    ...mapGetters(['tags']),
    mappedTags() {
      return this.tags.map(tag => ({
        id: tag.id,
        name: this.$i18n.t(`tags.${tag.name}`)
      }));
    }
  },
  data() {
    return {
      taskTypes,
      task: {
        title: '',
        notes: '',
        ruby: '',
        type: this.selectedTypeFilter,
        tags: []
      },
      rules: {
        required: validators.required(this.$i18n.t('common.requiredField')),
        isInteger: validators.isInteger(this.$i18n.t('common.rubyInteger')),
        minMaxRuby: validators.minMaxValue(
          rubyEnum.min,
          rubyEnum.max,
          this.$i18n.t('common.minMaxValue', {
            min: rubyEnum.min,
            max: rubyEnum.max
          })
        ),
        maxArrayLength: validators.minMaxArrayLength(
          MIN_TAGS,
          MAX_TAGS,
          this.$i18n.t('tasks.maxTagsLength', { number: MAX_TAGS })
        )
      },
      rubyEnum
    };
  },
  created() {
    if (this.selectedTask) {
      this.populateTask();
    }
  },
  methods: {
    populateTask() {
      if (this.selectedTask) {
        this.task = {
          ...this.selectedTask,
          tags: this.selectedTask.tags.map(tag => tag.id)
        };
      }
    },
    async save() {
      if (!this.$refs.manageGroupMissionTaskForm.validate()) {
        return;
      }

      const { id } = this.$route.params;

      if (this.task.id) {
        await this.$store.dispatch(UPDATE_GROUP_MISSION_TASK, {
          groupId: id,
          missionId: this.missionId,
          task: this.task
        });
      } else {
        await this.$store.dispatch(ADD_GROUP_MISSION_TASK, {
          groupId: id,
          missionId: this.missionId,
          task: this.task
        });
      }

      this.$emit('close');
    }
  }
};
</script>
