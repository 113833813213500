<template>
  <v-card tile>
    <v-toolbar flat color="app-blue" dark class="card-header">
      <span class="text-h5 font-weight-regular">
        {{ $t(`tasks.${task.type}`) }}
      </span>
      <v-spacer></v-spacer>
      <v-btn text icon @click="$emit('close')" class="mr-0">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-container class="px-4">
      <v-row align="center" class="pt-1 pb-2">
        <v-col cols="10">
          <v-card-title class="text-subtitle-1 pl-0 py-0">
            <span class="font-weight-medium">
              {{ task.title }}
            </span>
          </v-card-title>
          <v-card-text class="text-body-2 pl-0 py-0" v-if="task.notes">
            <span class="font-weight-light one-line">
              {{ task.notes }}
            </span>
          </v-card-text>
        </v-col>
        <v-col cols="2">
          <v-icon
            v-if="task.completed"
            color="grey lighten-1"
            class="fas fa-check-circle"
          ></v-icon>
          <v-icon v-else color="grey lighten-1" class="far fa-circle"></v-icon>
        </v-col>
      </v-row>
      <v-card-actions class="py-1 pl-0 mt-2">
        <task-tags v-if="task.tags.length > 0" :tags="task.tags"></task-tags>
        <v-spacer></v-spacer>
        <v-row align="center" justify="end" class="pb-2 pr-1">
          <ruby-label
            :ruby="task.ruby"
            :text-color="rubyColorText"
          ></ruby-label>
        </v-row>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import RubyLabel from '../../../shared/RubyLabel';
import TaskTags from '../../../tasks/TaskTags';
import tasksRubyColorMixin from '../../../../mixins/task.ruby.color.mixin';

export default {
  name: 'group-mission-task-info-modal',
  props: {
    task: Object
  },
  components: {
    RubyLabel,
    TaskTags
  },
  mixins: [tasksRubyColorMixin]
};
</script>
