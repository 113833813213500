<template>
  <v-card class="cursor pa-2" @click="editTask" tile :ripple="false">
    <v-container class="pa-0">
      <v-card-title
        class="py-0 mb-2 no-flex-wrap"
        :class="{ 'py-1': canManageTask, 'py-2': !canManageTask }"
      >
        <v-spacer></v-spacer>
        <actions-menu
          v-if="canManageTask"
          :canEdit="true"
          @edit="editTask"
          @delete="deleteTask"
        ></actions-menu>
      </v-card-title>
      <v-row align="center" justify="center">
        <v-col cols="2" class="pt-0">
          <v-btn
            v-if="task.completed"
            text
            icon
            large
            color="app-green"
            :disabled="!isMissionActive || canCopy || isLeader"
            @click.stop="reopenTask()"
          >
            <v-icon>mdi-check-circle</v-icon>
          </v-btn>
          <v-btn
            v-else
            text
            icon
            large
            color="app-green"
            :disabled="!isMissionActive || isLeader"
            @click.stop="completeTask()"
          >
            <v-icon>mdi-checkbox-blank-circle-outline</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="10" class="pt-0 pl-md-0">
          <v-card-title class="text-subtitle-1 pl-0 py-0">
            <span class="two-lines font-weight-regular">
              {{ task.title }}
            </span>
          </v-card-title>
          <v-card-text class="text-body-2 pl-0 py-0" v-if="task.notes">
            <span class="font-weight-light one-line">
              {{ task.notes }}
            </span>
          </v-card-text>
        </v-col>
      </v-row>
    </v-container>
    <v-card-actions class="py-1">
      <task-tags v-if="task.tags.length > 0" :tags="task.tags"></task-tags>
      <div v-else>&nbsp;</div>
      <v-spacer></v-spacer>
      <v-row align="center" justify="end" class="pr-1">
        <ruby-label :ruby="task.ruby" :text-color="rubyColorText"></ruby-label>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import ConfirmModal from '@/components/shared/ConfirmModal';
import ManageGroupMissionTaskModal from './ManageGroupMissionTaskModal';
import GroupMissionTaskInfoModal from './GroupMissionTaskInfoModal';
import ActionsMenu from '../../../shared/ActionsMenu.vue';
import RubyLabel from '../../../shared/RubyLabel';
import TaskTags from '../../../tasks/TaskTags';
import missionStatuses from '../../../../enums/mission.statuses';
import {
  COMPLETE_GROUP_MISSION_TASK,
  REOPEN_GROUP_MISSION_TASK,
  ARCHIVE_GROUP_MISSION_TASK,
  COPY_GROUP_MISSION_TASK,
  DELETE_GROUP_MISSION_TASK
} from '../../../../store/missions/tasks/types';
import tasksRubyColorMixin from '../../../../mixins/task.ruby.color.mixin';
import multiNotificationsMixin from '../../../../mixins/multi.notifications.mixin';

const GROUP_MISSION_TASK_INFO_MODAL_CONFIG = {
  width: '400px'
};

export default {
  name: 'group-mission-task',
  components: {
    ActionsMenu,
    RubyLabel,
    TaskTags
  },
  props: {
    mission: Object,
    task: Object,
    isLeader: {
      type: Boolean,
      default: false
    }
  },
  mixins: [tasksRubyColorMixin, multiNotificationsMixin],
  computed: {
    isMissionActive() {
      return this.mission.status === missionStatuses.active;
    },
    isMissionActiveOrPending() {
      return (
        this.isMissionActive || this.mission.status === missionStatuses.pending
      );
    },
    canManageTask() {
      return (
        this.isLeader &&
        !this.$route.query.userId &&
        (this.isMissionActiveOrPending ||
          this.mission.status === missionStatuses.pending)
      );
    },
    canCopy() {
      return this.task.completed && this.task.archived;
    }
  },
  methods: {
    async completeTask() {
      const { id } = this.$route.params;

      const { diff } = await this.$store.dispatch(COMPLETE_GROUP_MISSION_TASK, {
        groupId: id,
        missionId: this.mission.id,
        task: this.task
      });

      this.notifySuccess(diff, 'users.stats.success');
    },
    async reopenTask() {
      const { id } = this.$route.params;

      const { diff } = await this.$store.dispatch(REOPEN_GROUP_MISSION_TASK, {
        groupId: id,
        missionId: this.mission.id,
        taskId: this.task.id
      });

      this.notifyFailure(diff, 'users.stats.failure');
    },
    async editTask(event) {
      if (!this.isMissionActiveOrPending) {
        event.stopPropagation();
        return;
      }

      if (this.isLeader && !this.$route.query.userId) {
        await this.$root.$modal.show(ManageGroupMissionTaskModal, {
          selectedTask: { ...this.task },
          missionId: this.mission.id
        });
      } else {
        await this.$root.$modal.show(
          GroupMissionTaskInfoModal,
          {
            task: this.task
          },
          GROUP_MISSION_TASK_INFO_MODAL_CONFIG
        );
      }
    },
    async archiveTask() {
      const { id, userId } = this.$route.params;

      await this.$store.dispatch(ARCHIVE_GROUP_MISSION_TASK, {
        groupId: id,
        userId,
        missionId: this.mission.id,
        taskId: this.task.id
      });

      this.$notify({
        type: 'success',
        text: this.$root.$t('tasks.taskArchived')
      });
    },
    async copyTask() {
      const { id, userId } = this.$route.params;

      await this.$store.dispatch(COPY_GROUP_MISSION_TASK, {
        groupId: id,
        userId,
        missionId: this.mission.id,
        taskId: this.task.id
      });

      this.$notify({
        type: 'success',
        text: this.$root.$t('tasks.taskCloned')
      });
    },
    async deleteTask() {
      const confirmed = await this.$root.$modal.show(ConfirmModal, {
        text: this.$i18n.t('tasks.deleteTaskConfirm')
      });

      if (!confirmed) return;

      const { id } = this.$route.params;

      this.$store.dispatch(DELETE_GROUP_MISSION_TASK, {
        groupId: id,
        missionId: this.mission.id,
        taskId: this.task.id
      });
    }
  }
};
</script>
