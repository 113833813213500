import LevelUpModal from '../components/shared/LevelUpModal';

export default {
  methods: {
    notifySuccess(obj, keyPrefix) {
      for (const [key, value] of Object.entries(obj)) {
        if (key === 'level') {
          this.$root.$modal.show(
            LevelUpModal,
            {
              level: value
            },
            {
              width: 320
            }
          );
        } else {
          this.$notify({
            type: 'success',
            text: this.$root.$t(`${keyPrefix}.${key}`, { value })
          });
        }
      }
    },
    notifyFailure(obj, keyPrefix) {
      for (const [key, value] of Object.entries(obj)) {
        this.$notify({
          type: 'error',
          text: this.$root.$t(`${keyPrefix}.${key}`, { value })
        });
      }
    }
  }
};
