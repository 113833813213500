export default {
  board: {
    position: 'b-pos',
    boardWrapper: 'board-wrapper'
  },
  attributes: {
    href: 'href'
  },
  localStorageKeys: {
    toggleBoard: 'toggle-board'
  },
  events: {
    onTasksFilterChange: 'onTasksFilterChange'
  }
};
