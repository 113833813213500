<template>
  <v-card tile>
    <v-card-title>
      <v-row align="center" justify="center" class="pa-1">
        <div class="pt-2 bg-white">
          <v-img width="100" height="100" :src="trophy"></v-img>
        </div>
      </v-row>
    </v-card-title>
    <v-card-text class="text-center mb-5">
      <div class="text-h4 pb-2 app-blue--text font-weight-medium">
        {{ $t('users.stats.success.level') }}
      </div>
      <span class="text-subtitle-1">
        {{ $t('users.stats.success.levelReached', { value: level }) }}
      </span>
    </v-card-text>
    <v-card-actions>
      <v-btn color="app-blue" dark block @click="$emit('close')">
        {{ $t('common.woohoo') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'level-up-modal',
  props: {
    level: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      trophy: require('../../assets/images/logos/gamifinnopoly_small.png')
    };
  }
};
</script>
