<template>
  <div class="tasks-types-filter text-center">
    <v-chip
      v-for="filter of tasksTypesFilter"
      :key="filter.type"
      color="app-green"
      :outlined="!filter.selected"
      :dark="filter.selected"
      @click="selectFilter(filter)"
      class="mr-2"
    >
      {{ $t(`tasks.${filter.type}`) }}
    </v-chip>
  </div>
</template>

<script>
import commonVariables from '../../common/common.variables';

export default {
  name: 'tasks-types-filter',
  props: {
    filterName: {
      type: String,
      default: 'tasksTypesFilter'
    }
  },
  computed: {
    tasksTypesFilter() {
      return this.$store.getters[this.filterName];
    }
  },
  methods: {
    async selectFilter(filter) {
      this.$emit(commonVariables.events.onTasksFilterChange, filter.type);
    }
  }
};
</script>
